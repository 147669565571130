import { useEffect, useState } from 'react';

import {
  getMediaQueryStringByUseMediaQueryConfig,
  UseMediaQueryConfig,
} from './helpers';

/*
  This can be used to conditionally render stuff. It's handy sometimes.
  However, always prefer `npm @artsy/fresnel` (<Media> component) approach, whenever possible, for best SSR and browser first paint.
 */
const useMediaQuery = (config: UseMediaQueryConfig): boolean | undefined => {
  const [doesMatch, setDoesMatch] = useState<boolean>();

  useEffect(() => {
    const mediaQuery = getMediaQueryStringByUseMediaQueryConfig(config);
    const mediaQueryList = window.matchMedia(mediaQuery);

    setDoesMatch(mediaQueryList.matches);

    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', (event) => {
        setDoesMatch(event.matches);
      });
    } else {
      // Safari and IE case
      mediaQueryList.addListener((event) => {
        setDoesMatch(event.matches);
      });
    }
  }, []);

  return doesMatch;
};

export default useMediaQuery;
