import { OneOfType } from '../../helpers/utility-types';
import { BREAKPOINTS } from '../../styles/constants/breakpoints';
import { BreakpointName } from '../../styles/constants/breakpoints/types';

export enum UseMediaQueryOperator {
  Equal = 'equal',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  Between = 'between',
}

export type UseMediaQueryConfig = OneOfType<{
  [UseMediaQueryOperator.Equal]: BreakpointName;
  [UseMediaQueryOperator.LessThan]: BreakpointName;
  [UseMediaQueryOperator.LessThanOrEqual]: BreakpointName;
  [UseMediaQueryOperator.GreaterThan]: BreakpointName;
  [UseMediaQueryOperator.GreaterThanOrEqual]: BreakpointName;
  [UseMediaQueryOperator.Between]: [BreakpointName, BreakpointName];
}>;

interface BreakpointData {
  name: BreakpointName;
  startsAt: number;
}

const BREAKPOINTS_SORTED_ASC: BreakpointData[] = Object.entries(BREAKPOINTS)
  .map(([key, value]) => {
    const typedOutput: BreakpointData = {
      name: key as BreakpointName,
      startsAt: value,
    };
    return typedOutput;
  })
  .sort((a, b) => a.startsAt - b.startsAt);

const getBreakpointRange = (breakpointName: BreakpointName): Range => {
  const index = BREAKPOINTS_SORTED_ASC.findIndex(
    (item) => item.name === breakpointName,
  );
  const breakpoint = BREAKPOINTS_SORTED_ASC[index];
  const nextBreakpoint: BreakpointData | undefined =
    BREAKPOINTS_SORTED_ASC[index + 1];

  const startsAt = breakpoint.startsAt;
  const endsAt = nextBreakpoint ? nextBreakpoint.startsAt - 1 : Infinity;

  return [startsAt, endsAt];
};

const getNextBreakpointName = (
  breakpointName: BreakpointName,
): BreakpointName | undefined => {
  const index = BREAKPOINTS_SORTED_ASC.findIndex(
    (item) => item.name === breakpointName,
  );

  const nextBreakpoint: BreakpointData | undefined =
    BREAKPOINTS_SORTED_ASC[index + 1];

  return nextBreakpoint ? nextBreakpoint.name : undefined;
};

const getPreviousBreakpointName = (
  breakpointName: BreakpointName,
): BreakpointName | undefined => {
  const index = BREAKPOINTS_SORTED_ASC.findIndex(
    (item) => item.name === breakpointName,
  );

  const previousBreakpoint: BreakpointData | undefined =
    BREAKPOINTS_SORTED_ASC[index - 1];

  return previousBreakpoint ? previousBreakpoint.name : undefined;
};

export const MEDIA_QUERY_ALWAYS_MATCH = '';
export const MEDIA_QUERY_NEVER_MATCH = 'NEVER_MATCH';

type Range = [number, number];
const getMediaQueryStringByRange = ([startsAt, endsAt]: Range): string => {
  if (startsAt === Infinity || endsAt <= 0) {
    return MEDIA_QUERY_NEVER_MATCH;
  }

  if (startsAt <= 0 && endsAt < Infinity) {
    return `(max-width: ${endsAt}px)`;
  }

  if (startsAt > 0 && endsAt === Infinity) {
    return `(min-width: ${startsAt}px)`;
  }

  if (startsAt > 0 && endsAt < Infinity) {
    return `(min-width: ${startsAt}px) and (max-width: ${endsAt}px)`;
  }

  return MEDIA_QUERY_ALWAYS_MATCH;
};

export const getMediaQueryStringByUseMediaQueryConfig = (
  config: UseMediaQueryConfig,
): string => {
  if (config.equal) {
    const range = getBreakpointRange(config.equal);
    return getMediaQueryStringByRange(range);
  }
  if (config.greaterThanOrEqual) {
    const [startsAt] = getBreakpointRange(config.greaterThanOrEqual);
    return getMediaQueryStringByRange([startsAt, Infinity]);
  }
  if (config.greaterThan) {
    const nextBreakpointName = getNextBreakpointName(config.greaterThan);
    if (!nextBreakpointName)
      return getMediaQueryStringByRange([Infinity, Infinity]);

    const [startsAt] = getBreakpointRange(nextBreakpointName);
    return getMediaQueryStringByRange([startsAt, Infinity]);
  }
  if (config.lessThanOrEqual) {
    const [, endsAt] = getBreakpointRange(config.lessThanOrEqual);
    return getMediaQueryStringByRange([0, endsAt]);
  }
  if (config.lessThan) {
    const previousBreakpointName = getPreviousBreakpointName(config.lessThan);
    if (!previousBreakpointName) {
      return getMediaQueryStringByRange([0, 0]);
    }
    const [, endsAt] = getBreakpointRange(previousBreakpointName);
    return getMediaQueryStringByRange([0, endsAt]);
  }
  if (config.between) {
    const [startsAt] = getBreakpointRange(config.between[0]);
    const [, endsAt] = getBreakpointRange(config.between[1]);

    return getMediaQueryStringByRange([startsAt, endsAt]);
  }

  throw new Error(
    'getMediaQueryStringByUseMediaQueryConfig(): Error: use-case not covered: ' +
      JSON.stringify(config),
  );
};
