import { RectanglesPositions } from '@/components/common/LayoutWithLabel/types';

export const rectanglesPositions: RectanglesPositions = {
  desktop: [
    {
      x: -6,
      y: 24,
      width: 299,
      height: 21,
    },
    {
      x: 723,
      y: 41,
      width: 236,
      height: 21,
    },
    {
      x: 230,
      y: 120,
      width: 236,
      height: 14,
    },
    {
      x: 577,
      y: 140,
      width: 286,
      height: 21,
    },
    {
      x: 863,
      y: 200,
      width: 286,
      height: 21,
    },
  ],
  mobile: [{ x: -6, y: 24, width: 299, height: 21 }],
};
