import React from 'react';
import clsx from 'clsx';

import classes from './index.module.scss';

import { theme } from '@/styles/theme';

const VisualIcon: React.FunctionComponent<
  React.PropsWithChildren<{ fill: string }>
> = ({ fill }) => (
  <svg height="7px" viewBox="0 0 24 7" width="24px">
    <g>
      <polygon
        fill={fill}
        points="0.123046875 0.130371094 19.8608398 0.130371094 23.9790039 6.69140625 4.49316406 6.69140625"
      />
    </g>
  </svg>
);

export const Loader: React.FunctionComponent<
  React.PropsWithChildren<{ className?: string }>
> = ({ className }) => {
  return (
    <div className={clsx(classes.loader, className)}>
      <VisualIcon fill={theme.colors.blue} />
      <VisualIcon fill={theme.colors.blue} />
      <VisualIcon fill={theme.colors.blue} />
    </div>
  );
};
