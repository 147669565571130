import useSWR, { SWRConfiguration, SWRResponse } from 'swr';

import { fetchWrapper } from '@/helpers/fetchWrapper';

type SWRKey<T> = { url: string; params?: T };

const defaultConfig: SWRConfiguration = {
  revalidateOnFocus: false,
};

/**
 * A custom hook for fetching data using SWR  with a predefined configuration.
 *
 * @param {SWRKey<ParamType> | null} key - The unique key for the SWR hook, or null to disable the request.
 * @param {SWRConfiguration<DataType>} [config] - Optional configuration object for SWR.
 *
 * @returns {SWRResponse<DataType>} The response object from the SWR hook, containing data, error, and other state variables.
 *
 * @example
 * const { data, error, isLoading } = useAppSWR<{ name: string }, { id: number }>({ url: '/api/user', params: { id: 1 } });
 */
const useAppSWR = <DataType, ParamType = undefined>(
  key: SWRKey<ParamType> | null,
  config?: SWRConfiguration<DataType>,
): SWRResponse<DataType> => {
  return useSWR<DataType>(
    key,
    async ({ url, params }) => {
      const { data } = await fetchWrapper.get<DataType>(url, { params });
      return data;
    },
    { ...defaultConfig, ...config },
  );
};

export default useAppSWR;
